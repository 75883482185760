define(['app', 'uniqueIDHelper'], function (app, uniqueIDHelper) {

  var productRecentlyViewedProducts = function () {
    var component = {};
    let productId;

    var _config = {
      subscribeChannels: {
        recentlyViewed: 'productRecentlyViewed/update',
      },
      publishChannels: {
        recentlyViewedInitialised: 'recentlyViewed/initialised'
      },
    };

    var _init = function (element, userDriven) {
      component.element = element;

      if (!userDriven) {
        app.subscribe(component.config.subscribeChannels.recentlyViewed, component.retrieveNewRecentlyViewed);
      }
      uniqueIDHelper.ensureAriaLabelledByIdsAreUnique(component.element)

      return component;
    };

    const _retrieveNewRecentlyViewed = (masterId) => {
      productId = masterId
      return new Promise(() => app.ajax.get({
        url: '/list.linkedrecent?masterId=' + masterId,
        success: component.successHandler,
        error: component.errorHandler,
      }));
    };

    const _successHandler = (response) => {
      component.updateProductsRecentlyViewed(response);
    };

    const _errorHandler = function() {
      console.error(
        'ERROR: Could not retrieve new recently viewed products');
    };

    const _updateProductsRecentlyViewed = (response) => {
      const parent = component.element.parentNode;
      if (parent !== null) {
        parent.innerHTML = response;
        const newElement = parent.querySelector('[data-component=productRecentlyViewedProducts]');
        app.publish(component.config.publishChannels.recentlyViewedInitialised, productId)
        component.init(newElement, true);
      }
    };

    component.config = _config;
    component.init = _init;
    component.retrieveNewRecentlyViewed = _retrieveNewRecentlyViewed;
    component.updateProductsRecentlyViewed = _updateProductsRecentlyViewed;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;

    return component;
  };

  return productRecentlyViewedProducts;
});

